
<template>
    <v-container class=" flex fill-heigth">
    <v-card
       elevation="10"
       class="mx-auto mt-6 fill-heigth"       
       
   >
   <v-toolbar
            ligth
            cards                
            flat
            color="green lighten-4"
        >
            <v-card-title class="text-h6 font-weight-regular">
            {{cmpOptions.title}}
           </v-card-title>

       </v-toolbar>
       <v-divider></v-divider>
       <v-container class="fill-heigth">
        <v-stepper v-model="e1">
            <v-stepper-header>
                <v-stepper-step
                    :complete="true"
                    step="1"
                    @click="goToStep(1)"
                >
                    Allgemein
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step
                    :complete="true"
                    step="2"
                    @click="goToStep(2)"
                >
                    Spritzmittel
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step
                    :complete="true"
                    step="3"
                    @click="goToStep(3)"
                >
                    Felder
                </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
                <v-stepper-content step="1">    
                    <InputMaskAddEdit :item="editItem" :isEdit="options.isEdit" :isVisible="isVisible"></InputMaskAddEdit>
                </v-stepper-content>
            </v-stepper-items>
            <v-stepper-items>
                <v-stepper-content step="2">    
                    <InputMaskAddEditProduct :item="editItem" :isEdit="options.isEdit" :isVisible="isVisible"></InputMaskAddEditProduct>
                </v-stepper-content>
            </v-stepper-items>  
            <v-stepper-items>
                <v-stepper-content step="3">    
                    <InputMaskAddEditFields :item="editItem" :isEdit="options.isEdit" :isVisible="isVisible"></InputMaskAddEditFields>
                </v-stepper-content>
            </v-stepper-items>             
        </v-stepper>         
  
                  <v-divider></v-divider>
                   <v-card-actions>
                   <v-btn
                       
                       outlined
                       @click="stepBackwards"
                   >
                       Zurück
                   </v-btn>
                   <v-spacer></v-spacer>
                   <v-btn
                       :disabled="false"
                       :loading="isLoading"
                       dark
                       color="primary"
                       depressed
                       @click="submit"              
                   >
                       Weiter
                   </v-btn>
               </v-card-actions>
          
       </v-container>
   </v-card>
</v-container>
</template>

<script>
   export default {
 
        components:{
            InputMaskAddEdit: () => import("../../components/ctrl/InputMasks/protectionplanBase.vue"),
            InputMaskAddEditProduct:() =>import("../../components/ctrl/InputMasks/protectionplanProduct.vue"),
            InputMaskAddEditFields:() =>import("../../components/ctrl/InputMasks/protectionplanFields.vue"),
             
        },

       props:{
           item: {
               required: false,
               type: Object,
               default: () => undefined
           },

           isVisible:{                
               required: false,
               default: false,
               type: Boolean
           }
       },

       data() {
           return {
               defaultOptions:{
                   title: 'Title',
                   isEdit: true
               },
               defaultItem: {
                    id:"",
                    name: "",
               },
               editItem: {
                    id:"",
                    name: "",
               },
               options:{
                    title: "Spritzplan hinzufügen", 
                    isEdit: false,
                },


               isLoading: false,
               message: '',
               errMessage: '',
               form: false,
               e1:1

           }
       },

       computed:{            

           currentUser() {
               return this.$store.state.auth.user;
            },

           cmpOptions(){   
               return {...this.defaultOptions,...this.options}
           },

           captionBtnSubmit(){
               return this.cmpOptions.isEdit ? 'Ändern' : 'Speichern';
           },


       },

       methods:{ 
            stepBackwards(){              
                    
                    this.e1 = this.e1 - 1
                },

            stepForward(){
                
                this.e1 = this.e1 + 1
                // console.debug('debug', this.editField)
            },     
            
            goToStep(step){
                console.debug('goToStep', step)
            
                this.e1 = step
            },


            submit(){   
                
                this.stepForward()
               console.debug('submit', this.editItem)

            },
           
           
            removeItem(item) {
                console.debug('removeItem', item)
                const index = this.selectedItems.indexOf(item.id)
                if (index >= 0) this.selectedItems.splice(index, 1)
            },



       },
       watch: { 
           isVisible: function(newVal) { // watch it
               this.errMessage  = '';
               console.debug('isVisible');
               if(newVal){
                   this.$refs.form.reset();
                   if(this.cmpOptions.isEdit){
                       console.debug('isVisible', this.item);
                       this.editItem = JSON.parse(JSON.stringify(this.item));
                   }
                   else{
                       
                       this.editItem = JSON.parse(JSON.stringify(this.defaultItem));
                   }
               }
               else{
                   this.editItem = JSON.parse(JSON.stringify(this.defaultItem));
               }

               console.log('Prop changed: isVisible', newVal, ' | user: ', this.editItem)
           }
       },
       mounted() {
           this.errMessage  = '';
           console.debug('mounted');
           if(this.cmpOptions.isEdit){
               console.debug('isVisible', this.item);
               this.editItem = JSON.parse(JSON.stringify(this.item));
           }
           else{
               
               this.editItem = JSON.parse(JSON.stringify(this.defaultItem));
           }
       }

   }

</script>